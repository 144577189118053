<template>
    <div class="page">
        <app-header :navIndex="1"></app-header>
        <section class="section-container" style="margin-top: 100px">
<!--            <div class="col-md-5 ma-auto mt-md-12 mt-sm-0 pt-md-12 pt-sm-0">-->
<!--                <v-img :src="$utils.formatImgUrl(imgUrl)"></v-img>-->
<!--            </div>-->
            <div class="d-flex flex-wrap justify-space-between mb-12 mt-12">
                <div class="item-box mb-4" v-for="(item,index) in list" :key="index">
                    <v-hover v-slot:default="{ hover }">
                        <v-card class="pa-5" :elevation="hover ? 6 : 0"
                                :class="{ 'on-hover': hover }">
                            <div class="text-md-center item-title">{{item.name}}</div>
                            <div class="desc mt-5 text-left">{{item.address}}</div>
                            <div class="desc mt-2 mb-2" v-if="item.phone">
                                {{$i18n.t('contact.tel') + '：' + item.phone}}
                            </div>
                            <div class="desc" v-if="item.fax">{{$i18n.t('contact.fax') + '：' + item.fax}}</div>
                        </v-card>
                    </v-hover>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import AppHeader from "../../components/core/header/index";
    export default {
        components: {AppHeader},
        data(){
            return {
                imgUrl: 'company/map_bg.jpg',
                banner: {
                    imgUrl: 'company/about/bg_one.jpg',
                    title: '联系我们',
                    enTitle: 'CONTACT US'
                },
                list: this.$i18n.t('contact.list')
            }
        },
        mounted(){
            if(document.getElementById('h1Id')) {
                document.getElementById("bodyId").removeChild(document.getElementById('h1Id'))
            }
            let div = document.createElement('h1')
            div.style = "opacity: 0"
            div.id = "h1Id"
            div.innerHTML = "联系我们"
            document.getElementById("bodyId").appendChild(div);
        },
    }
</script>
<style lang="scss" scoped>
    .item-box {
        cursor: pointer;
        width: 390px;
        .item-title {
            font-size: 18px;
        }
        .on-hover {
            .item-title {
                color: $primary-color;
            }
        }
    }

    @media(max-width: $screen-md) {
        .item-box {
            width: 100%;
            .item-title {
                font-size: 14px;
            }
        }
    }
</style>
